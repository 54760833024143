<template>
  <LayoutGridComponent
    :class="$style.root"
    data-break-early=""
  >
    <SharedThumbnailProgramComponent
      :data="thumbnail"
      v-for="thumbnail in thumbnails"
      :key="thumbnail.id"
      :class="$style.item"
      :data-status="getStatus(thumbnail)"
    />
  </LayoutGridComponent>
</template>

<script setup>
const props = defineProps({
  thumbnails: Array
})

const getStatus = (thumbnail) => {
  return getItemProgramStatus(thumbnail)
}
</script>

<style module lang=scss>
.root {
  position: relative;


  padding-left: var(--unit--horizontal--tripple);
  padding-right: var(--unit--horizontal--tripple);
}

.item[data-status="upcoming"],
.item[data-status="current"] {
    grid-column: 1 / -1;
}
</style>
