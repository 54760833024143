<template>
  <div :class="[$style.root, $style[status]]" :data-status="status">
    <div :class="$style.info">
      <div v-if="tag" :class="$style.tag">{{ tag }}</div>
      <CoreDateTimeRangeComponent
        :class="$style.dateTimeRange"
        v-for="range in localizedData?.date_time_ranges"
        :key="range?.id"
        :dateStart="range?.date_time_start"
        :dateEnd="range?.date_time_end"
      />
    </div>

    <CoreImageComponent
      v-if="localizedData?.cover_image?.data?.attributes"
      :image="localizedData?.cover_image?.data?.attributes"
      :class="$style.image"
    />

    <CoreTextComponent
      v-if="introduction"
      :class="$style.introduction"
      :string="introduction"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  data: Object,
  status: String
})

const localizedData = computed(() => {
    return i18nGetLocalizedData(baseStrapiGetAttributes(props?.data))
})

const introduction = computed(() => {
  return baseTruncateString(localizedData?.value?.introductions?.current_locale || localizedData?.value?.introductions?.i18n_richtext_en, 20)
})

const tag = computed(() => {
  return localizedData?.value?.tags?.current_locale || localizedData?.value?.tags?.i18n_text_en
})
</script>

<style module lang="scss">
.root {
  margin: auto;
  width: 100%;


  --column--width: 40ch;
}

.image {
  display: block;
}

.info {
  text-align: right;
  @media (max-width: $breakpoint-s) {
    text-align: left;
    display: flex;
    flex-direction: column-reverse;
  }
}

.dateTimeRange {
  display: block;
}

.dateTimeRange time {
  display: inline-block;
}

.tag {
  text-transform: uppercase;
  word-break: break-all;
}

/* 1 */
.current,
.upcoming {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  gap: var(--unit--spacer);

  @media (max-width: $breakpoint-l) {
    display: grid;
    grid-template-areas: 'image image'
                         'dateTimeRange introduction';
    grid-template-columns: min-content 1fr;
    grid-column-gap: var(--unit--horizontal--double);
    grid-row-gap: var(--unit--spacer);
    align-items: start;
  }

  @media (max-width: $breakpoint-s) {
    grid-template-areas: 'image'
                         'dateTimeRange'
                         'introduction';
    grid-template-columns: 1fr;
    grid-column-gap: var(--unit--horizontal--double);
    grid-row-gap: var(--unit--spacer);
    align-items: start;
  }
}

.current .image,
.upcoming .image {
  @media (min-width: $breakpoint-l) {
    width: min(calc(100% - var(--column--width) - var(--unit--horizontal--tripple)), var(--container--default--without-padding));
  }

  @media (max-width: $breakpoint-l) {
    grid-area: image;

    max-width: var(--container--default--without-padding);
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.current .dateTimeRange,
.upcoming .dateTimeRange {
  @media (min-width: $breakpoint-l) {
    width: calc(var(--column--width) / 2);
    text-align: right;
  }

  @media (max-width: $breakpoint-l) {
    grid-area: dateTimeRange;
    white-space: nowrap;
  }

  @media (max-width: $breakpoint-s) {
    text-align: left;
  }
}

.current .introduction,
.upcoming .introduction {
  @media (min-width: $breakpoint-l) {
    width: calc(var(--column--width) / 2);
  }

  @media (max-width: $breakpoint-l) {
    grid-area: introduction;
  }

  @media (max-width: $breakpoint-s) {
    text-align: left;
  }
}

/* 2 */
.past {
  display: grid;
  grid-template-areas: 'image image'
                       'dateTimeRange introduction';
  grid-template-columns: min-content 1fr;
  grid-column-gap: var(--unit--horizontal--double);
  grid-row-gap: var(--unit--spacer);
  align-items: start;

  @media (max-width: $breakpoint-s) {
    grid-template-areas: 'image'
                         'dateTimeRange'
                         'introduction';
    grid-template-columns: 1fr;
    grid-column-gap: var(--unit--horizontal--double);
    grid-row-gap: var(--unit--spacer);
    align-items: start;
  }
}

.past .image {
  grid-area: image;

  max-width: var(--container--default--without-padding);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.past .dateTimeRange {
  grid-area: dateTimeRange;
  white-space: nowrap;
}

.past .introduction {
  grid-area: introduction;
}
</style>
