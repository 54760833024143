export const getItemProgramStatus = (item: object) => {
  const localizedData = i18nGetLocalizedData(baseStrapiGetAttributes(item))
  if (localizedData?.date_time_start && localizedData?.date_time_end) {
    switch (true) {
      case dayJs().isBefore(localizedData?.date_time_start):
        return 'upcoming'
      case dayJs().isBetween(localizedData?.date_time_start, localizedData?.date_time_end):
        return 'current'
      case dayJs().isAfter(localizedData?.date_time_end):
        return 'past'
      default:
        return 'unknown'
    }
  } else {
    switch (true) {
      case dayJs().isBefore(localizedData?.date_time_start):
        return 'upcoming'
      case dayJs().isSameOrAfter(localizedData?.date_time_start):
        return 'current'
      default:
        return 'unknown'
    }
  }
}
