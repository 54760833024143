<template>
  <i18nLink
    :class="$style.root"
    :to="{
      name: 'program-slug',
      params: {
        slug: slug
      }
    }"
    :data-status="status"
  >
    <div
      v-if="status !== 'unknown'"
      :class="$style.status"
    >
      {{ $t(`program.status.${status}`) }}
      <LayoutHorizontalLineComponent />
    </div>

    <div :class="$style.title">
      {{ title }}
    </div>

    <SharedThumbnailProgramDetailsComponent
      :data="data"
      :status="status"
    />
  </i18nLink>
</template>

<script setup>
const props = defineProps({
  data: Object,
  forcePastStatus: {
    type: Boolean,
    default: false
  }
})

const localizedData = computed(() => {
    return i18nGetLocalizedData(baseStrapiGetAttributes(props?.data))
})

const title = computed(() => {
  return localizedData?.value?.titles?.current_locale || localizedData?.value?.titles?.i18n_title_en
})

const slug = computed(() => {
  return localizedData?.value?.slugs?.current_locale || localizedData?.value?.slugs?.i18n_slug_en
})

const status = props?.forcePastStatus ? 'past' : getItemProgramStatus(props?.data)
</script>

<style module>
.root {
  display: flex;
  flex-direction: column;
}

.root:hover  {
  color: var(--color--accent);
}

.status {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--unit--spacer);

  max-width: var(--container--default--without-padding);
  width: 100%;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: calc(var(--unit--spacer) * 2);

  text-transform: uppercase;
}

.title {
  composes: font-size-large from global;
  margin-bottom: var(--unit--spacer);
  text-align: center;
}
</style>
